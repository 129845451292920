@import "../../App/stylesheet/colors.scss";

.events-loader {

  ul {
    margin-left: 0;
  }

  li * {
    margin-top: 0;
    line-height: 1.25;
  }

  &__title{
    p{
      display: inline;
      margin-bottom: 0;
    }
  }

  &__item {
    margin-bottom: 1rem;

    &:hover {
      background: transparentize($grey, .9);
    }
  }
}
