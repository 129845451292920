@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";

.series-item {

  align-items: stretch;
  border-bottom: 1px solid transparentize($grey, .8);
  display: flex;
  padding: 1rem 0;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }

  &__name {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    font-size: 1.25rem;
    font-weight: 500;
    justify-content: center;
    color: $orange;
    margin-right: 1rem;
    padding-top: 1rem;
    text-align: center;
    width: 100%;

    @include md {
      width: 15%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    a {
      text-align: left;
    }
  }

  &__description {
    display: flex;
    width: 100%;

    @include md {
      width: calc(80% - 100px - 4rem);
    }

    li {
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    ul {
      padding: 0;
    }
  }

  &__status {
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    text-align: left;
  }

  &__image {

    display: flex;
    flex-grow: 0;
    padding: 0 2rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    @include md {
      margin-top: 0;
      justify-content: flex-start;
      width: calc(4rem + 100px);
    }

    img {
      max-width: 100px;
      max-height: 100px;
    }
  }


  &__no-photo {
    align-items: center;
    background: transparentize($grey, .9);
    color: transparentize($grey, .5);
    display: flex;
    font-size: .75rem;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    text-align: center;
  }
}
