@import "../../../App/stylesheet/colors.scss";

.file-section-item {

  height: auto;
  transition: all .15s linear;

  h2 {
    cursor: pointer;
    margin-bottom: 0;
    color: $link-blue;

    &.visited {
      color: $violet;
    }
  }

  &__content {
    margin: 1rem 0;
  }

  &__description {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }
}
