@import "../../App/stylesheet/colors.scss";

.bank-info {

  color: $blue-light;
  cursor: pointer;
  font-weight: 600;

  i {
    opacity: 0;
    transition: all .15s ease-in-out;
  }

  &:hover {

    color: $blue-dark;

    i {
      opacity: 1;
    }
  }
}
