@import "../../../App/stylesheet/colors.scss";


.product-status {
  display: inline-flex;
  font-style: italic;
  font-weight: normal;

  &.green {
    color: $success;
  }

  &.red {
    color: $red;
  }

  &.grey {
    color: transparentize($grey, .5);
  }

  &.blue {
    color: $blue-light;
  }

  .icon-tooltip{
    font-size: 1rem;
    color: $blue-light;
    margin-left: 0.25rem;
  }

}
