@import "../../App/stylesheet/breakpoints.scss";

.product {

  &__main-image {
    display: block;
    margin: 2rem auto auto auto;
    max-width: 400px;

  }

  &__optional-content-wrapper {

    padding-top: 1.5rem;

    &.open {

      //border-top: 1px solid hsla(0, 0%, 50.2%, .2);

    }

    img {


      margin: auto;
      display: block;
      max-width: 100%;

      @include lg {
        max-width: 800px;
      }
    }
  }

  &__optional-content-toggle {
    margin-top: -0.75rem;
    height: auto;
    transition: height 0.5s;
  }

}

.dialog-enter {
  opacity: 0.01;
  transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all 300ms;
}