@import "../../App/stylesheet/colors.scss";
@import "../../App/stylesheet/breakpoints.scss";

.footer {

  background: $black;
  color: transparentize($white, .5);
  font-size: .95rem;
  font-weight: 400;
  padding: 4rem 2rem 2rem;
  width: 100vw;

  h2 {
    color: $white;
    font-size: 1.5rem;
    margin-top: 0;
    text-transform: none;
  }

  h3 {
    color: $white;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0;
    text-align: center;

    @include md {
      text-align: left;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    &::before {
      content: none;
    }
  }

  a {
    color: $grey;
    display: flex;
    justify-content: center;
    margin-bottom: .35rem;
    text-decoration: none;

    @include md {
      justify-content: flex-start;
    }

    &:hover {
      color: $white;
    }
  }

  .copyright {
    font-size: .9rem;
    margin-top: 2rem;
    text-align: center;
    border-top: 1px solid $grey;
    padding-top: 1rem;
  }

  .mission {
    margin-bottom: 3rem;
    text-align: center;

    @include md {
      padding-right: 2rem;
      text-align: left;
    }

    &__title {
      color: $white;
    }

    &__alliance {
      font-size: 1.25rem;
      margin-left: 20px;
    }
  }

  .social-bar {

    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    @include lg {
      justify-content: center;
    }

    i {
      color: inherit;
      font-size: 1.25rem;
      margin-right: .55rem;
      transform: none;
    }

    a {
      display: flex;
      line-height: 1;
      margin-bottom: 0;
    }
  }

  div.text--center a{
    justify-content: center;
  }
}
