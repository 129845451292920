@import "colors.scss";
@import "breakpoints.scss";

.page {

  li {
    * {
      margin-top: -1.5rem;
    }

    ul {
      margin-top: 0;
    }

    li {
      margin-top: 0;

      li {
        margin-top: 0;
      }
    }
  }

  h2{
    p{
      font-size: inherit;
    }
  }

  &__section {
    &--with-margin {
      //margin: 4rem 0;
      padding: 1rem 0;

      @include md {
        padding: 4rem 0;

      }
    }

    &--top-padding {
      padding-top: 2rem;

      @include md {
        padding-top: 4rem;
      }

    }

    &--bottom-padding {
      padding-bottom: 2rem;

      @include md {
        padding-bottom: 4rem;
      }

    }

    &--with-theme {
      background: transparentize($grey, .90);
      padding: 4rem 0;
    }
  }

  &.product {

    .product {
      &__main-image {
        display: block;
        margin: auto;
        margin-top: 2rem;
      }
    }

  }
}


.background-on-hover {
  &:hover {
    background: transparentize($grey, .90);
  }

}