@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";

.promo-box {

  background-color: $text-default;
  color: $white;
  overflow: hidden;
  position: relative;

  &__background {
    background: url("../../../App/images/background/blava-dark-bk.jpg");
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  &__title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;

    &.center{
      @include md{
        text-align: center;
      }
    }

    @include md {
      text-align: left;
      font-size: 2.8rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

  }

  &__content {

    //background: linear-gradient(transparent, transparentize($blue-dark, .1));
    position: relative;
    z-index: 2;

    .container.primary {
      background: transparentize($black, .5);
      padding: 4rem 1rem;
    }
  }

  &__proof {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1.2rem;
    font-weight: 700;
    justify-content: center;
    margin-top: 0;
    padding: 1rem 0;

    @include md {
      flex-direction: row;
    }

    a {
      display:flex;
      margin:0;
      max-width:90px;
      width:80%;
    }

    img {
      max-width: 90px;
      padding: 0 1rem;
    }

     .low_power {
       display:flex;
       color:#ffffff;
       min-width:100px;
     }

    .reliability {
      display:flex;
      color:#ffffff;
      min-width:200px;
    }

    .wireless{
      display: flex;
      color: #ffffff;
      min-width: 270px;
    }
  }
}
