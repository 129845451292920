@import "../../App/stylesheet/colors.scss";


.generic-item {

  box-shadow: 0 1px 2px transparentize($black, .9);
  color: $blue-dark;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  padding: .5rem;


  p {
    display: inline;
    padding: 0;
    margin: 0;
  }
}
