@import "../../../App/stylesheet/colors.scss";

.success-message {
  background: transparentize($success, .7);
  color: $success;
  display: flex;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}
