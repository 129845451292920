@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";


.subcategory-item {

  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include md {
      flex-direction: row;
      align-items: initial;
    }
  ;

  }

  &__title {
    align-items: center;
    display: flex;
    font-size: 1.65rem;
    justify-content: center;

    @include md {
      justify-content: flex-start;
    }
  }

  &__image {

    margin-right: 2rem;

    img {
      max-width: 100px;
    }
  }

  &__description {

    color: $text-default;
    text-align: center;

    @include md {
      text-align: left;
    }

  }
}
