@import "../../App/stylesheet/colors.scss";

.link {

  &-item {

    color: $link-blue;
    cursor: pointer;
    display: flex;
    flex-direction: row;
  &:hover {
      color: $orange;
  }

  &:visited {
      color: $violet;
  }
    .form {
      flex: 1;
    }

    &__icon{
      margin-right: .5rem;
    }
  }
}
