@import "../../../App/stylesheet/colors.scss";

.file-item {

  border: 1px solid transparentize($grey, .75);
  border-radius: 5px;
  color: $text-default;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: .35rem;
  padding: .5rem 1rem;
  text-decoration: none;

  table {


  }

  td {
    &:first-of-type {
      width: 20%;
    }
  }

  &__actions {
    display: flex;
    flex: none;
    flex-grow: 0;
    flex-wrap: nowrap;

  }

  &__icon {
    margin-right: 0.5rem;
  }

  &__name {
    flex-grow: 1;
    flex-wrap: wrap;
    display: flex;

    a {
      text-decoration: none;
      display: inline-flex;
    }
  }

  .action {
    color: $blue-light;
    cursor: pointer;
    margin-left: 1rem;
    text-decoration: none;
    text-wrap: avoid;
    transition: all .1s linear;

    &:focus,
    &:active {
      color: $red;
    }

    i {
      color: inherit;
      font-size: inherit;
      margin-right: .25em;
    }
  }


  &__description {
    font-weight: 400;
  }

  i {
    font-size: 1.2rem;
    margin-right: .25rem;
  }
}
