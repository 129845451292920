.video-item {

  margin-bottom: 2rem;

  &__wrapper {
    max-width: 100%;

    iframe {
      width: 100%;
    }
  }
}
