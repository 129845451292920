.thumbnail-photo-admin {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    >div{
      margin-right: 5px;
    }
  }
}
