@import "../App/stylesheet/breakpoints.scss";

.modal-popup {

  border-radius: 0;
  position: relative;
  border: 1px solid #004d81;
  z-index: 999;
  background: white;
  padding: 50px 20px 20px 20px;
  height: 100%;
  width: 100%;

  .icon-close{
    color: rgba(0,0,0,.2);
    font-size: 25px;
  }

  img{
    max-width: 100%;
  }

  @include md{
    width: auto;
    height: auto;
  }
}