@import "../../App/stylesheet/colors.scss";

.dropzone {

  border: 1.5px dashed $grey;
  border-radius: 5px;
  color: $grey;
  cursor: pointer;
  margin-bottom: 2rem;
  text-align: center;
  transition: all .1s linear;

  &:hover,
  &:active,
  &:focus {
    background: transparentize($grey, .8);
  }

  p{
    text-align: center;
  }

}
