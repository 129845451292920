$black: #000;
$grey: #808080;
$orange: #ff6634;
$text-default: #333f50;
$pink: #ff005d;
$red: #e31e24;
$success: #51ac5c;
$white-smoke: #f5f5f5;
$white: #fff;
$blue-light: #19f;
$blue-dark: #004d81;
$blue-grey: #adb9ca;
$blue-light-tone: #d9e9ff;
$violet: #551A8B;
$link-blue: #1a0dab;
$light-red: #ff6666;
