@import "../../../App/stylesheet/colors.scss";

.case-study-item {

  background-color: #efefef;
  border-color: #c0c0c0;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  height: 320px;
  justify-content: center;
  margin-bottom: 1rem;
  overflow: hidden;
  padding: 0;
  width: 100%;

  h4 {
    align-items: center;
    color: $orange;
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    height: 3.4rem;
    justify-content: center;
    margin-bottom: .5rem;
    margin-top: .5rem;
    text-align: center;
  }

  &__photo {


    align-items: center;
    background: $white;
    color: $grey;
    display: flex;
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: 600;
    justify-content: center;
    padding: .25rem;

    img {

      border-radius: 8px;
      display: block;
      margin-top: 0;
      max-width: 99%;

    }
  }

}
