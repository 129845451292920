@import "../../App/stylesheet/colors.scss";

.tag {
  align-items: center;
  background: $blue-light-tone;
  border-radius: 1rem;
  border: 2px solid transparentize($white, .85);
  box-shadow: 0 1px 2px transparentize($blue-light, .8);
  color: lighten($black, 55%);
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  line-height: 1;
  margin-bottom: .5rem;
  margin-right: .5rem;
  padding: .45rem .65rem .45rem .85rem;
  transition: all .15s ease-out;


  &.selected {
    background: $blue-light;
    filter: saturate(1.3);
    border: 2px solid transparentize($white, .85);
    color: $white;
    text-shadow: 0 1px 2px transparentize($blue-dark, .2);
  }

  &:not(.selected):hover {
    background: $blue-light;
    color: $white;
  }

  &.inline {

    font-size: .75rem;
    color: $white;
    padding: 0.15rem .5rem;
    transform: translateY(-1px);
    margin: 0;

    &.green {
      background: $success;
    }


  }


  &.red {
    background: $red;
    color: $white;

    &:hover {
      background: $red;
      color: $white;
    }
  }


  i {
    display: inline-block;
    transform: translateY(1px);

    &.icon-user {
      font-size: .75rem;
    }
  }

  span {
    display: inline-block;
    margin-right: 2px;
    transform: translateY(-1px);
  }

  div {
    vertical-align: baseline;
  }
}
