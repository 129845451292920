@import "./colors.scss";
@import "./buttons.scss";

.form {
  input,
  select,
  textarea {
    background: transparentize($grey, .93);
    border: 0;
    border-radius: 2px;
    box-sizing: border-box;
    color: $text-default;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    margin: 5px 0;
    padding: 10px;
    text-align: left;
    width: 100%;

    textarea {
      max-width: 100%;
      min-height: 10rem;
    }

    &.blank {
      color: transparentize($white, .8);
    }

    &:disabled {
      background: transparentize($text-default, .9);
      color: transparentize($text-default, .5);
    }

    option {
      color: $text-default;

      &:disabled {
        color: transparentize($text-default, .55);
      }
    }

    &:focus {
      outline-color: $white;
    }

    &:required {
      //background: transparentize($red, .83);
      // border: 2px solid transparentize($red, .83);
    }

    //&.recommended {
    //  background: transparentize($blue-system, .83);
    //  border: 2px solid transparentize($blue-system, .83);
    //}

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance:textfield;
    }
  }

  label {

    color: $text-default;
    display: block;
    flex-direction: row;
    font-size: .95rem;
    margin-top: 1rem;

    &.checkbox {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      [type="checkbox"] {
        box-shadow: none;
        cursor: pointer;
        display: inline-flex;
        flex-grow: 0;
        margin: 0;
        min-width: 4rem;
        padding-right: 1rem;
        transform: scale(2);
        width: auto;
      }

      em {
        flex-grow: 1;
        font-size: .95rem;
        font-style: normal;
        text-align: left;
        text-transform: none;
      }
    }
  }

  textarea {
    font-weight: normal;
    max-width: 100%;
    min-height: 30rem;
  }

  &__group {
    margin-bottom: 1rem;
  }

  &__required {
    color: $red;
    margin-left: .15rem;
  }

}

.email-attachment {

  align-items: center;
  background: transparentize($grey, .8);
  cursor: pointer;
  display: flex;
  padding: 1rem;

  i {
    display: inline-flex;
    margin-right: .75rem;
  }

}
