@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";

.faq-item {

  &__name {
    color: $link-blue;
    cursor: pointer;
    font-weight: 400;
    transition: all .15s ease-in-out;

    &:hover {
      color: $orange;
    }
  }

  &.visited {

    .faq-item__name{
      color: $violet;
    }

  }

  &__content {
    background: transparentize($grey, .90);
    border: 1px dotted transparentize($grey, .8);
    margin: 0 2rem 1rem;
    padding: 1.5rem 2rem;

    blockquote {
      margin: 0;
      padding: 0;
    }
  }

  &__link {

    font-size: .85rem;
    text-align: center;
    word-break: break-word;

    @include md {
      text-align: right;
    }
  }
}
