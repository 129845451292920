@import "../../../App/stylesheet/colors.scss";

.photo-item {

  $photoSize: 150px;

  border-radius: 5px;
  border: 4px solid $grey;
  cursor: pointer;
  display: flex;
  height: $photoSize;
  margin-bottom: 1rem;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;
  transition: all .1s linear;
  width: $photoSize;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  img {
    max-height: 250px;
    max-width: 250px;
  }

  &__action-panel{
    bottom: 0;
    display: flex;
    position: absolute;
    right: 0;
  }

  button{
    background: darken($grey, 20%);
    border-radius: 0;
    border: 0;
    color: $white;
    cursor: pointer;
    display: flex;
    height: 2rem;
    justify-content: center;
    text-align: center;
    transition: all .15s linear;
    width: 2rem;

    &:hover {
      background: darken($grey, 10%)
    }
  }

  &__light-box{
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
