@import "../../../App/stylesheet/colors.scss";

.error-message {
  background: transparentize($red, .7);
  color: $red;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
}
