// Default file for entire app
@import "./stylesheet/colors.scss";
@import "./stylesheet/icons.scss";
@import "./stylesheet/typography.scss";
@import "./stylesheet/forms.scss";
@import "./stylesheet/tables.scss";
@import "./stylesheet/page.scss";
@import "./stylesheet/lists.scss";
@import "./stylesheet/images.scss";
@import "./stylesheet/breakpoints.scss";

* {
  box-sizing: border-box;
}

::selection {
  background: lighten($blue-light, 10%);
  color: $white;
}

html,
body {

  background-attachment: fixed;
  background-color: $white;
  background-position: 50% 0;
  background-size: cover;
  color: $text-default;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  padding: 0;

  .app-wrapper {
    display: flex;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  color: $text-default;
  font-family: Calibri, Arial, sans-serif;
  font-weight: 500;
  margin-bottom: .5rem;
  margin-top: 1rem;
  line-height: 1.2;
}

h1 {

  color: $orange;
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: .5rem;
  text-align: center;

  @include md {
    font-size: 2.5rem;
    text-align: left;
  }
}

h2 {
  color: $orange;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  margin-top: 0;

  @include md {
    font-size: 2rem;
    text-align: left;
  }
}

h3 {
  color: $orange;
  font-size: 1.75rem;
  text-align: center;

  @include md {
    font-size: 1.75rem;
    text-align: left;
  }
}

h4 {
  font-size: 1.5rem;
}

hr {

  border-bottom: 1px solid transparentize($grey, .8);
  border-top: 0;
  display: block;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

p {
  text-align: justify;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

a,
.link {

  color: $link-blue;
  cursor: pointer;
  text-decoration: none;

  &.link {
    color: $link-blue;
    text-decoration: none;
  }

  &.image-link {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    color: $orange;
  }

  &:visited {
    color: $violet;
  }
}

ul {

  padding: 0;

  @include md {
    margin-left: 0px;
  }
}


.app-wrapper {
  display: flex;
  justify-content: center;
  position: static;
  transition: background .5s ease-in-out;
  width: 100%;

  .app-theme {
    display: flex;
    justify-content: center;
    position: static;
    transition: background .5s ease-in-out;
    width: 100%;
  }

}

.inline {
  display: flex;
  justify-content: space-between;
}

.ril__image {
  background: $white;
  padding: 2rem;
}


.icon-youtube-logo {
  color: $red;
  display: inline-flex;
  font-size: 200%;
  transform: translateY(10px);
}


.ReactModal__Overlay {
  &--after-open {
    display: flex;
    justify-content: center;
    align-items: center;


    z-index: 999;
  }
}


