@import "../../../App/stylesheet/colors.scss";

.event-item-admin {

  align-items: center;
  box-shadow: 0 1px 2px transparentize($black, .9);
  color: $blue-dark;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: .5rem;
  width: 100%;

  &:hover {
    background: $blue-light-tone;
  }

  &__options {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__name {
    //font-weight: 800;
    p{
      display: inline;
    }
  }

  &__description {
    display: block;
    font-size: 0.85rem;
    opacity: 0.75;

    p{
      font-size: inherit;
    }
  }

  &__title {
    color: transparentize($black, .5);
    font-size: .85rem;
  }

  p {
    margin: 0;
    padding: 0;
  }

  i {
    display: flex;
    padding-right: .5rem;
    transform: translateY(10px);
  }
}
