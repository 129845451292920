@import "../../../App/stylesheet/colors.scss";

.thumbnail {

  border: 2px solid $grey;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-width: 100px;
  overflow: hidden;

  img {
    max-height: 100px;

  }


  &:hover {
    border: 2px solid $blue-light;
  }
}
