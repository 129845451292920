@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";

.mobile-navigation {

  background: $blue-dark;
  border-bottom: 8px solid $blue-grey;

  ul{
    padding: 0;
    margin-left: 0;
  }

  &__logo {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    height: 60px;

    a, span {
      align-items: center;
      display: flex;
      height: 40px;
      margin-top: 10px;
      margin-left: 10px;
    }

    img {
      display: block;
      height: 50px;
      width: auto;
    }
  }

  &__switcher {
    margin: 10px 25px 10px 10px;
    height: 40px;
    background: transparent;
    border: 2px solid $white;
    border-radius: 5px;
    color: $white;
    align-items: center;
    font-size: 1.25rem;
    line-height: 1;
    padding: 0 .75rem;

    i {
      display: inline-flex;
      transform: translateY(1px);
    }

    &.active,
    &:focus {
      background: $white;
      color: $blue-dark;
    }
  }

  &__content {

  }

  &__options {
    display: flex;
    justify-content: space-between;
  }

  &__list {

    color: transparentize($white, .5);
    padding: 0 !important;


    a,
    span {
      color: transparentize($white, .5);
      cursor: pointer;
      display: flex;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 1rem;

      i {
        font-size: 0.5rem;
        margin-left: .5rem;
        transform: translateY(6px);
      }
    }

    ul {
      padding: 0;
    }

    li {

      padding: 0;
      line-height: 1;

      &:before {
        content: none;
      }
    }
  }

  &__secondary {

    background: transparentize($white, .9);

    li {

    }

    a {
      color: $white;
      cursor: pointer;
      font-size: 1rem;
      padding: 1rem 2rem;
    }

  }

  &__bottom-panel {

    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $white;

    a {
      color: $white;
      font-size: 1.25rem;
    }
  }
}
