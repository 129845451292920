@import "../../App/stylesheet/colors.scss";

.toggle-button {
  background: none;
  border: 1px solid transparent;

  h2 {
    color: $link-blue;
  }

  cursor: pointer;
  width: 100%;


  &:hover,
  &:focus,
  &.active {

    border: 1px solid $grey;
    cursor: pointer;
    outline: none;
  }
}

.slick-slider {
  .slick-prev:before,
  .slick-next:before {
    color: $blue-dark !important;
    //font-size: 60px!important;
  }
}

.lowpower-frame {
  border: 1px solid #000000;
  background-color: #fffccf;
  padding: 8px;
}
.lowpower {
  &__table {
    th {
      background: lighten($grey, 45%);
      text-transform: none;
    }

    td,
    th {
      border: 1px solid;
      padding: 1rem;
    }

    tr
    {
      border: 1px solid #000;
    }

    tr:last-child td {
      border: 1px solid #000;
    }
  }
}


