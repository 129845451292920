@import "colors.scss";

table {

  border-collapse: collapse;
  border: none;
  width: 100%;
  padding: 1rem;

  tbody {
    margin: 1rem;
  }

  th {
    background: transparent;
    color: $text-default;
    padding: .5rem;
    text-align: left;
  }

  td {
    padding: .5rem;
    border: none;
    border-bottom: 1px solid transparentize($grey, .7);
  }

  tr {
    margin: 1rem;

    &:last-child {
      td {
        border: none;
      }
    }
  }

}

.table {
  &--with-links {
    td {

      padding: .5rem;

      &:last-child {
        width: 30%;
      }
    }
  }

  &--wrapper {
    padding: 1rem 2rem;
    border: 1px solid transparentize($grey, .7);
  }

  &--functions {
    th {
      background: transparentize($grey, .8);
      text-transform: none;
    }

    td {
      background: transparentize($grey, .9);

      &:last-child {
        background: none;
      }
    }
    td,
    th,
    tr:last-child td {
      border: 1px solid lighten($grey, 20%);
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &--crosstable {
    th {
      background: transparentize($grey, .8);
      text-transform: none;
    }

    td {
      background:none;

      &:first-child {
        background: transparentize($grey, .9); 
      }
    }
    td,
    th,
    tr:last-child td {
      border: 1px solid lighten($grey, 20%);
    }
    tr:nth-child(6)>td:nth-child(2) {
      background: transparentize($grey, .9); 
    }
    tr:nth-child(15)>td:nth-child(2) {
      background: transparentize($grey, .9); 
    }
    tr:nth-child(17)>td:nth-child(2) {
      background: transparentize($grey, .9); 
    }
    tr:nth-child(27)>td:nth-child(2) {
      background: transparentize($grey, .9); 
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &--os-history {
    th {
      background: transparentize($grey, .8);
      text-transform: none;
      border: 1px solid lighten($grey, 20%);
    }

    td:first-of-type {
      background: transparentize($grey, .85);
    }

    td,
    tr:last-child td {
      border: 1px solid lighten($grey, 20%);
    }
  }

  &--os-dpa {
    th {
      background: transparentize($grey, .85);
      text-transform: none;
      border: 1px solid lighten($grey, 20%);
      text-align: center;
    }

    td:first-of-type {
      background: transparentize($grey, .85);
      text-align: center;
    }

    td,
    tr:last-child td {
      border: 1px solid lighten($grey, 20%);
      text-align: center;
    }

    tr:nth-child(3)>td:nth-child(2) {
      background: transparentize($grey, .85); 
    }
  }
}
