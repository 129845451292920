@import "../../../App/stylesheet/colors.scss";

.distributor {


  &__flag {
    margin-right: 1rem;
    width: 3rem;
  }


  &__country {
    //background: transparentize($grey, .9);
    align-items: stretch;
    background: transparentize($grey, .9);
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    transition: all .15s ease-in-out;

  }

  &__clickable {
    align-items: center;
    background: transparentize($grey, .9);
    cursor: pointer;
    display: flex;
    padding: 1rem;
    width: 100%;

    &:hover {
      background: $orange;
    }
  }

  &__list {

    //margin-top: 0;
    //max-height: 50rem;
    //position: relative;
    //transition: margin-top 1s ease;
    //transition: max-height .8s;
    overflow: hidden;

    &.hidden {
      max-height: 0;
    }
  }

  &__item {
    flex-grow: 1;
    overflow: hidden;
    padding: 1rem;
    position: relative;


    hr {
      border-bottom: 1px solid transparentize($grey, .85);
      border-left: 0;
      border-right: 0;
      border-top: 0;
      opacity: 0;
      padding-top: 1rem;
    }
  }

  &__logo {

    align-items: center;
    align-self: stretch;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding-top: 1rem;

    img {
      max-height: 4rem;
    }
  }
}
