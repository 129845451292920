@import "../../App/stylesheet/colors.scss";

.text--orange-bold {
  color: $orange;
  font-weight: bold;
}

.dpa-calculator {
  table,
  td,
  th {
    border: 1px solid #DEE1E6;

    .NADR {
      background: #a0d9ec;
    }

    .PNUM {
      background: #f4c918;
    }

    .PCMD {
      background: #d5cfeb;
    }

    .HWPID {
      background: #7abe6b;
    }

    .PDATA {
      background: #7ff0d0;
    }

    .CRC {
      background: #f07f60;
    }
  }
  .table--with-results {
    display: block;
    overflow-y: auto;
  }


  form {
    .form-control {
      padding: 10px;
      display: inline-block;

      .NADR, .PNUM, .PCMD, .HWPID {
        width: 50px;
      }

      .PDATA {
        width: 440px;
      }

    }
  }

}

