@import "../../../App/stylesheet/breakpoints.scss";

.why-box {

  &__item {
    align-items: stretch;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;

    @include md {
      flex-direction: row;
    }
  }

  &__title,
  h3 {
    font-family: Calibri, Arial, sans-serif;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 1.3rem;
    font-weight: bold;
  }

  h3 a {
    color: #ff6634;
  }
  p {
    margin-top: .25rem;
    text-align: center;

    @include md {
      text-align: left;
    }
  }

  &__picture {

    align-items: center;
    display: flex;
    flex-grow: 0;
    min-width: 80px;
    justify-content: center;


    img {
      display: block;
      max-width: 60px;
    }
  }

  &__content {
    flex-grow: 1;
  }
}
