@import "../../../App/stylesheet/colors.scss";


.navigation {

  background: $blue-dark;
  background: linear-gradient(135deg, $blue-dark 0%, $blue-light 100%);
  color: $white;
  width: 250px;

  h2 {
    font-size: .8rem;
    padding: 1rem 1rem 0;
  }

  h2,
  a {
    color: $white;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
    padding-left: 0;

    &::before {
      content: none;
    }

    &:first-of-type {
      a {
        border-top: 1px solid transparentize($white, .9);
      }
    }
  }

  a {
    background: transparent;
    border-bottom: 1px solid transparentize($white, .9);
    border-top: 1px solid transparent;
    color: transparentize($white, .1);
    display: flex;
    font-weight: 500;
    padding: .5rem 1.5rem;

    i {
      font-weight: normal;
      margin-right: .5rem;

    }

    &:hover {
      background: $blue-light;
      border-bottom: 1px solid transparentize($white, .95);
      color: $white;
    }
  }

}
