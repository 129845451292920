@import "../../../App/stylesheet/colors.scss";

.public-scene-wrapper {

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;

  > div:not(.footer):not(.main-navigation) {
    flex-grow: 1;
  }

  > .container {
    flex-grow: 1;
  }

  > div:not(.container) {
    width: 100vw;
  }

  > .footer {
    flex-grow: 0;
  }

  h1 {
    margin-top: 2rem;
  }

}
