@import "../../../App/stylesheet/colors.scss";

.shnyaga {
  border-radius: 13px;
  cursor: pointer;
  display: inline-block;
  transition: background .1s ease;

  &::after {
    background: $white;
    border-radius: 12px;
    content: "";
    display: block;
    left: 4px;
    position: relative;
    top: 3px;
    transition: all .2s ease-in-out;
  }

  &--normal {
    height: 24px;
    width: 48px;
  }

  &--normal::after {
    height: 18px;
    width: 18px;
  }

  &--normal.shnyaga--checked::after {
    left: 26px;
    top: 3px;
  }

  &--disabled {
    background-color: $grey !important;
    cursor: not-allowed;
  }
}
