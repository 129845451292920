@import "../../App/stylesheet/colors.scss";

.news-loader {

  ul {
    margin-left: 0;
  }

  li * {
    margin-top: 0;
  }

  &__item {

    display: flex;
    margin-bottom: .25rem;
    padding-right: 0.5rem;
    justify-content: space-between;

    &:hover {
      background: transparentize($grey, .9);
    }

    p {
      padding: 0;
      margin: 0;
      display: inline-block;
    }

    &.full{
      flex-direction: column;
    }
  }

  &__inner-content{
    margin-top: 0.0rem;
    margin-bottom: 1rem;
  }

  &__content {
    padding-right: 0.5rem;
    flex-wrap: nowrap;
  }

  &__posted {
    float: right;
    opacity: 0.8;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-style: italic;
  }
}
