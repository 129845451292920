@import "../../App/stylesheet/colors.scss";

.training {

  &__table {

    th {
      background: lighten($grey, 45%);
      text-transform: none;
    }

    td,
    th,
    td:last-child
    {
      border: 1px solid lighten($grey, 20%);
      padding: 1rem;
    }
  }

}