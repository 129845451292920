@import "../../../App/stylesheet/colors.scss";

.product-gallery {
  margin-bottom: 2rem;

  &__item {

    border: 1px solid $grey;
    cursor: pointer;
    display: inline-flex;
    height: 150px;
    margin-bottom: .5rem;
    margin-right: .5rem;
    width: 150px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      border-color: $orange;
    }

    img {

    }
  }
}
