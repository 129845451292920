@import "../../../App/stylesheet/colors.scss";

.editor-scene-wrapper {

  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100%;
  width: 100%;

  a:hover {
    color: $blue-light
  }


  > div:not(.footer):not(.navigation) {
    flex-grow: 1;
  }

  > .footer {
    flex-grow: 0;
  }

  h1 {
    color: $blue-dark;
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  h2 {
    color: $grey;
    font-size: 1.25rem;
  }

  &__content {
    flex-grow: 1;
    width: calc(100vw - 250px);
    margin-left: 1rem;
  }
}
