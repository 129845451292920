@import "../../App/stylesheet/colors.scss";

.patents {

  tr {
    border-top: 1px dashed transparentize($grey, .5);

    &:nth-child(odd) {
      background: transparentize($grey, .9);
    }

    &:last-child {
      border-bottom: 1px dashed transparentize($grey, .5);
    }
  }

  td {
    padding: 1.5rem 0;
  }

  td:first-of-type {
    font-weight: 600;
    padding-left: 2rem;
    width: 20%;

  }
}
