@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";

.main-navigation {

  align-items: stretch;
  background: $blue-dark;
  border-bottom: 8px solid $blue-grey;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: space-between;
  width: 100%;
  z-index: 3;

  @media (min-width: 1080px) and (max-width: 1395px){
    height: 130px;
  }

  @include lg {
    background: $blue-dark;
  }


  &__top-panel {
    display: flex;
    justify-content: flex-start;

    @media (min-width: 1080px) and (max-width: 1395px){
      flex-direction: column;
    }
  }


  ul.main-navigation__list,
  ul.main-navigation__list-left {
    align-items: stretch;
    display: flex;
    flex-grow: 1;
    height: 70px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    //transform: translateY(8px);

    > li {
      display: inline-flex;
      margin-left: 0;
      padding-left: 0;
      position: relative;
      margin-bottom: 0;

      &::before {
        content: none;
      }

      > a,
      > span {

        border-bottom: 8px $blue-grey solid;
        color: $white;
        cursor: pointer;
        display: flex;
        font-size: 1.15rem;
        font-weight: bold;
        letter-spacing: .25px;
        padding: 1rem .75rem 0.5rem .75rem;
        text-decoration: none;
        transition: all .15s linear;

        &.active {
          border-bottom: 8px $blue-dark solid;
        }

        &:not(.active):hover,
        &.hover-active {
          border-bottom: 8px $orange solid;
          color: $white;
        }
      }
    }
  }

  ul.main-navigation__list-left {
    flex-grow: 0;
    justify-self: flex-end;
  }

  &__logo {
    align-items: flex-start;
    display: none;
    height: 64px;
    justify-content: flex-start;
    position: absolute;

    @include xxl {

      display: inline-flex;
      z-index: 3;

    }

    &--inside {

      a, span {
        align-items: center;
        display: flex;
        height: 60px;
      }

      img {
        display: block;
        height: 44px;
        padding-left: 0;
        width: auto;
        z-index: 1;

        @media (min-width: 1080px) and (max-width: 1395px){
          padding-left: 10px;
          margin-top: 5px;
        }
      }

      @media (min-width: 1395px) {
        // Hide double row
        display: block;
      }

      @media (min-width: 1550px) {
        // Hide double row
        display: none;
      }

    }

    a, span {
      align-items: center;
      display: flex;
      height: 60px;
    }

    img {
      display: block;
      height: 44px;
      padding-left: 1rem;
      width: auto;
      z-index: 1;
    }
  }

  &__motto {

    align-items: center;
    color: transparentize($white, .15);
    display: none;
    height: 64px;
    justify-content: flex-start;
    padding-right: 1rem;
    position: absolute;
    right: 0;

    @include xxl {
      display: inline-flex;
    }
  }

  &.expanded {
    height: 100vh;
  }

  .icon-expand-button {
    display: inline-flex;
    font-size: .7rem;
    transform: translateY(0.35rem);
  }

  &__active-area {

    height: 100%;
    position: absolute;
    width: 100%;

  }
}
