@import "./colors.scss";

ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

li {

  line-height: 1.35;
  list-style-type: none;
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.5rem;


  &.ril-toolbar__item {
    &:before {
      content: none;
    }
  }

  &::before {

    color: $orange;
    content: "■";
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    height: 100%;
    left: 0;
    line-height: .4;
    position: absolute;
    transform: translateY(2.5px);
    width: 1.5rem;
  }

  ul {
    margin-left: 0;
    margin-bottom: 0;
  }

  li {
    padding-left: 1.25rem;

    ul {
      margin-left: 0;
    }

    &::before {

      color: $orange;
      content: "■";
      display: inline-block;
      font-size: 1.25rem;
      font-weight: bold;
      transform: translateY(4px);
      width: 1.25rem;
    }

    li {

      padding-left: 1rem;

      ul {
        margin-left: 0;
      }

      &::before {

        color: $orange;
        content: "■";
        font-size: .85rem;
        display: inline-block;
        font-weight: bold;
        transform: translateY(7px);
        width: 1.0rem;
      }
    }
  }
}
