@import "../../App/stylesheet/colors.scss";


.trademarks {

  &-table {

    border-collapse: collapse;
    display: table;
    flex-direction: column;
    font-size: .95rem;
    width: 100%;

    &__row {
      display: table-row;

      &--heading {
        background: transparentize($grey, .8);
        font-weight: 600;

        & > div {
          display: table-cell;
          vertical-align: bottom;
        }
      }

      & > div {
        border-collapse: collapse;
        border: 1px solid transparentize($grey, .8);
        display: table-cell;
        padding: .5rem;
      }
    }
  }
}
