@import "../../App/stylesheet/colors.scss";
@import "../../App/stylesheet/tables.scss";

.revert-text {
  /*writing-mode: vertical-rl;*/
  transform: rotate(-90deg);
  white-space: nowrap;
}

.rotated-th {
  /**
  * Since the rotated text is taken out of the DOM flow (position: absolute), we
  * need to artificially consume vertical space so that the rotated headers
  * don't overlap with the content above the table.
  */
  height: 260px ;
  position: relative ;
  padding:10px;
}

.rotated-th__label {
  bottom: 10px ;
  left: 50% ;
  position: absolute ;
  transform: rotate( -90deg ) ;
  transform-origin: center left ;
  white-space: nowrap ;
}
