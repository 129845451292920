@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=latin-ext");
@import "./colors.scss";

.text {

  &--red {
    color: $red !important;
  }

  &--success {
    color: $success;
  }

  &--orange {
    color: $orange;
  }

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--cursive {
    font-style: italic;
  }

  &--smaller {
    font-size: 80%;
  }

  &--clickable {
    cursor: pointer;
  }

}

i {
  &.icon-external-link {
    &::before {
      font-size: .75rem;
      opacity: .5;
      font-weight: bold;
      display: inline-block;
      transform: translateY(-2px);
    }
  }
}
