.faq {

  &-section {

    margin-bottom: 2rem;
    //&__title {
    //
    //}
  }

  &-item {

    &__availability {
      font-size: .85rem;
      opacity: .35;
    }

    img {
      display: block;
      margin: auto auto 1rem;
      max-width: 100%;
    }

  }
}
