
@import "../../../App/stylesheet/colors.scss";

.description {
  color: $text-default;
  font-size: .85rem;
  font-weight: 400;
  margin-bottom: 1rem;

  &.inline {
    display: inline-flex;
    margin-bottom: 0;
  }
}
