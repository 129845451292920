@import "../../../App/stylesheet/colors.scss";

.sub-navigation {

  //background: $white;
  bottom: 0;
  box-shadow: 0 5px 5px transparentize($text-default, .8);
  display: inline-flex;
  flex-grow: 0;
  flex-wrap: nowrap;
  position: absolute;
  transform: translateY(100%);
  width: auto;
  z-index: 5;

  $minWidth: 230px;



  &__primary-panel {
    min-width: $minWidth;
    padding: 2rem 0;
    background: $white;
  }

  &--compact {

    min-width: 170px;

  }

  &__second-panel {
    border-left: 1px solid transparentize($grey, .8);
    min-width: $minWidth;
    background: $white;
    //padding: 2rem 0;
  }

  &__third-panel {
    border-left: 1px solid transparentize($grey, .8);
    min-width: $minWidth;
    padding: 2rem 0;
    background: $white;
  }

  &__fourth-panel {
    border-left: 1px solid transparentize($grey, .8);
    display: flex;
    min-width: $minWidth;
    background: $white;
  }

  &__title {
    font-weight: 600;
    padding: .25rem 1.5rem;
  }

  ul {
    padding: 0;
  }

  li {
    display: flex;
    list-style-type: none;

    &::before {
      content: none;
    }
  }

  a, .archive-submenu {
    color: black;
    display: block;
    padding: .25rem .5rem .25rem 1.5rem;
    width: 100%;

    &.pseudo-hovered,
    &.active-path {
      background: transparentize($grey, 0.8);
      color: $grey;
    }

    &:hover {
      background: $blue-light;
      color: $white;
    }

  }

  &__content {

    border-left: 20px solid rgba(128, 128, 128, 0.2);
    height: 100%;
    padding: 1.25rem 1.5rem;
    position: relative;


    img {
      display: block;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      max-width: 100%;
      position: relative;
    }

  }

  &__archive-wrapper {

    padding: 1rem 1.5rem 0;
  }

  &__top-bottom-padding {
    padding: 2rem 0;
  }

  i {

    font-size: 0.45rem;
    align-self: flex-end;
    float: right;
    margin-right: 3px;
    display: flex;
    height: 100%;
    opacity: 0.5;
    transform: translateY(6px);
  }
}


.archive-submenu{
  cursor: pointer;
}