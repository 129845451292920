@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";

.product-variant-item {

  box-shadow: 0 1px 2px transparentize($black, .9);
  color: $text-default;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: .5rem;
  width: 100%;

  @include md {
    display: inline-flex;
    width: 50%;
  }

  @include xl {
    display: inline-flex;
    width: 30%;
    margin: 1rem;
  }

  i {
    color: $orange;
  }

  &:hover {
    background: $blue-light-tone;
  }

  .thumbnail {
    max-width: 75px;
  }

  &__series {
    color: $orange;
    font-size: .85rem;
  }

  &__name {
    padding-left: 1rem;
  }

  &__image {
    margin-right: 1rem;
    padding: 0.5rem;
  }

  .ordering-box__no-image {
    height: 75px;
    padding: 1rem;
  }
}
