@import "colors.scss";

.image {

  &--preview {
    max-width: 350px;
  }

  //&--thumb {
  //
  //}
}
