@import "../../../App/stylesheet/colors.scss";

.related-downloads {

  margin-top: 2rem;

  &__item {

    border-radius: 3px;
    border: 1px solid transparentize($grey, .8);
    display: flex;
    margin-bottom: .25rem;
    padding: 1rem;
    width: 100%;

    p {
      display: inline;
    }
  }

}