@import "colors.scss";


.btn {

  align-content: center;
  align-items: center;
  background: $blue-light;
  border: 2px solid lighten($blue-light, 10%);
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .5px;
  line-height: 1.1;
  outline: none;
  padding: .5rem 1rem;
  transition: all .25s ease-in-out;

  &:visited {
    color: $white;
  }

  &--unimportant {

    background: transparentize($light-red, .85);
    border: 2px solid transparentize($light-red, .85);
    color: $grey;
    padding: .5rem 1rem;
    text-align: center;

    &:hover {
      background: $red;
    }

  }

  &--danger {
    background: $red;
    border: 2px solid lighten($red, 10%);
    color: $white;
  }

  &:hover {

    background: $orange;
    border: 2px solid transparentize($orange, .85);
    color: $white !important;

  }
}


.clickable {

  cursor: pointer;

}
