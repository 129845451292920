@font-face {
    font-family: "custom-icons";
    src: url("../fonts/custom-icons.eot?4a0d7461548ee478aaa155c08bc4b16b?#iefix") format("embedded-opentype"),
url("../fonts/custom-icons.woff2?4a0d7461548ee478aaa155c08bc4b16b") format("woff2"),
url("../fonts/custom-icons.woff?4a0d7461548ee478aaa155c08bc4b16b") format("woff"),
url("../fonts/custom-icons.ttf?4a0d7461548ee478aaa155c08bc4b16b") format("truetype"),
url("../fonts/custom-icons.svg?4a0d7461548ee478aaa155c08bc4b16b#custom-icons") format("svg");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
    content: "\f101";
}
.icon-back-arrow:before {
    content: "\f102";
}
.icon-calendar:before {
    content: "\f103";
}
.icon-close:before {
    content: "\f104";
}
.icon-code:before {
    content: "\f105";
}
.icon-copy:before {
    content: "\f106";
}
.icon-delete:before {
    content: "\f107";
}
.icon-distributors:before {
    content: "\f108";
}
.icon-doc:before {
    content: "\f109";
}
.icon-download:before {
    content: "\f10a";
}
.icon-edit:before {
    content: "\f10b";
}
.icon-events:before {
    content: "\f10c";
}
.icon-exe:before {
    content: "\f10d";
}
.icon-expand-button:before {
    content: "\f10e";
}
.icon-external-link:before {
    content: "\f10f";
}
.icon-facebook-logo:before {
    content: "\f110";
}
.icon-file:before {
    content: "\f111";
}
.icon-files:before {
    content: "\f112";
}
.icon-link:before {
    content: "\f113";
}
.icon-linkedin-logo:before {
    content: "\f114";
}
.icon-markdown:before {
    content: "\f115";
}
.icon-menu:before {
    content: "\f116";
}
.icon-news:before {
    content: "\f117";
}
.icon-note:before {
    content: "\f118";
}
.icon-pdf:before {
    content: "\f119";
}
.icon-photos:before {
    content: "\f11a";
}
.icon-play-button:before {
    content: "\f11b";
}
.icon-playstore-logo:before {
    content: "\f11c";
}
.icon-products:before {
    content: "\f11d";
}
.icon-rss-logo:before {
    content: "\f11e";
}
.icon-search:before {
    content: "\f11f";
}
.icon-shipping:before {
    content: "\f120";
}
.icon-shopping-cart:before {
    content: "\f121";
}
.icon-software:before {
    content: "\f122";
}
.icon-sort-down:before {
    content: "\f123";
}
.icon-tooltip:before {
    content: "\f124";
}
.icon-twitter-logo:before {
    content: "\f125";
}
.icon-use-case:before {
    content: "\f126";
}
.icon-warning-sign:before {
    content: "\f127";
}
.icon-warning:before {
    content: "\f128";
}
.icon-webinar:before {
    content: "\f129";
}
.icon-youtube-logo:before {
    content: "\f12a";
}
.icon-zip:before {
    content: "\f12b";
}
