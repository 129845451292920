@import "../../../App/stylesheet/colors.scss";

.case-study-item-admin {

  box-shadow: 0 1px 2px transparentize($black, .9);
  color: $text-default;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: .5rem;
  width: 100%;

  i {
    color: $orange;
  }

  &:hover {
    background: $blue-light-tone;
  }

  &__options {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }


  &__title {
    color: transparentize($black, .5);
    font-size: .85rem;
  }
}
