@import "../../../App/stylesheet/colors.scss";

.distributor-item-admin {

  box-shadow: 0 1px 2px transparentize($black, .9);
  color: $text-default;
  cursor: pointer;
  font-weight: 500;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: .5rem;

  &:hover {
    background: $blue-light-tone;
  }

  &__flag {

    border: 1px solid $grey;
    margin-right: .5rem;
    max-width: 1.5rem;
  }

}
