@import "../../../App/stylesheet/colors.scss";
@import "../../../App/stylesheet/breakpoints.scss";

.ordering-box {

  border-bottom: 1px solid transparentize($grey, .75);
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  margin-top: 2rem;

  &__header {
    background: $text-default;
    color: $white;
    font-size: 1.25rem;
    margin-bottom: .5rem;
    text-align: center;


    .content {
      padding: .5rem 0
    }
  }

  &__product-name {
    align-items: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;

    @include md {
      border-right: 1px solid $grey;
    }
  }

  &__product-description {
    padding: 1rem;

    li {
      margin-bottom: 1rem;

      p {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }

    @include md {
      border-right: 1px solid $grey;
    }
  }

  &__item {
    border-top: 1px solid $grey;
    padding-top: .25rem;
  }

  &__product-status {
    font-style: italic;
    margin-top: 1rem;
  }

  &__options {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__disablebtn {
    background-color: #66ccff;
    border-color: #66ccff;
  }

  &__disablebtn:hover {
    background-color: #19f;
    border-color: #19f;
  }

  &__product-image {
    background: transparentize($grey, .9);
  }

  &__no-image {
    color: transparentize($grey, .65);
    font-size: .85rem;
    font-weight: 600;
    padding: 2rem 1rem;
  }

  &__image {

    display: flex;
    flex-grow: 0;
    padding: 0 2rem;
    width: calc(4rem + 100px);

    img {
      max-width: 100px;
      max-height: 100px;
    }
  }

  &__white-right-line {
    border-right: 1px solid $white;
  }

}